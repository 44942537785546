// Grid Unit - 8px
export const GU = 8

// These breakpoints values represent minimum screen sizes.
export const BREAKPOINTS = {
  min: 360,
  small: 360,
  medium: 96 * GU,
  large: 144 * GU,
}
